import { template as template_56b637b5f29a4042baaa61360d9a8d14 } from "@ember/template-compiler";
const FKControlMenuContainer = template_56b637b5f29a4042baaa61360d9a8d14(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
