import { template as template_e566ad8669554e4587cd076052d2188c } from "@ember/template-compiler";
const SidebarSectionMessage = template_e566ad8669554e4587cd076052d2188c(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
